/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "label_colors_default": "",
  "label_size_default": "text-headline-xs",
  "label_variant_default": "ml-2.5",
  "input_colors_default": "border-gray-300 hover:border-gray-600 focus:border-success-500 focus:hover:border-success-500 focus:shadow-success-500 focus-visible:shadow-success-500 focus-visible:outline-none checked:border-success-400 checked:hover:border-success-400 checked:focus:border-success-400 checked:focus:shadow-success-500 checked:focus-visible:outline-none checked:focus:hover:border-success-400 checked:focus:after:border-success-700",
  "input_size_default": "h-6 w-6",
  "input_variant_default": "appearance-none rounded-full bg-clip-content border-2 focus:shadow-[0px_0px_8px] focus-visible:shadow-[0px_0px_8px] checked:focus:after:border-2 checked:focus:after:block checked:focus:after:rounded-full checked:focus:after:h-6 checked:focus:after:w-6 checked:focus:after:absolute checked:focus:after:top-0 checked:focus:after:left-0 checked:focus:shadow-[0px_0px_8px] checked:border-7",
  "colors_default": "",
  "size_default": "my-4",
  "variant_default": "flex items-center my-4 relative"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RadioButton");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;