import { JwVideoArticle } from '@/components/JwVideo/Article';
import { LabradorComponent } from '@/types/component';

import { getB2BVideoInfo, getVideoOptions } from 'base/components/JwVideo/Article/utils/dataParser';

export const MotherhoodLabradorJwVideoArticle: LabradorComponent = ({ data, meta, type }) => {
  const videoOptions = getVideoOptions(data);
  const b2bVideoInfo = getB2BVideoInfo(data);

  const { enableDesc, fieldSettings, durationMinutes, playerSrc, teaserLink, metadata } = data;

  const titleTextSize = fieldSettings?.title?.textSize;

  return (
    <JwVideoArticle
      playerSrc={playerSrc}
      videoData={videoOptions}
      b2bVideo={b2bVideoInfo}
      showDescription={enableDesc}
      durationMinutes={durationMinutes}
      videoPageLink={teaserLink}
      titleTextSize={titleTextSize}
      metadata={metadata}
      disableLazyLoad
      verticalFloatingMode
    />
  );
};
