import { ArticleTeaserBodytext } from '@/components/ArticleTeaser/Bodytext';
import { LabradorComponent } from '@/types/component';
import { gridSystemBreakpoints } from 'base/configs/gridSystem';

export const MotherhoodLabradorArticleTeaserBodytext: LabradorComponent = ({ type, data, meta, descendants }) => {
  const imageUrl = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data?.imageUrl ?? {};
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= gridSystemBreakpoints.sm;

  const { category, categoryUrl, title, publishedUrl, kicker, footer, options } = data ?? {};

  return (
    <ArticleTeaserBodytext
      links={{
        article: { href: publishedUrl },
        category: { href: categoryUrl || category },
      }}
      headline={kicker}
      description={title}
      footer={footer}
      image={isMobile ? imageUrl.mobile : imageUrl.desktop}
      options={{ variant: 'fourth', ...options }}
      data-content-index={meta.contentIndex}
    />
  );
};
