/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneShowsSlider as ResolvedStandaloneShowsSlider } from 'base/components/standalone/ShowsSlider';

export const ShowsSlider = ResolvedStandaloneShowsSlider;

export type ShowsSliderProps = PropsFromComponent<typeof ShowsSlider>;

export const ShowsSliderWith = (extras: DynamicStandaloneExtras): typeof ShowsSlider => {
    return function ShowsSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ShowsSlider');
        return <ResolvedStandaloneShowsSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}