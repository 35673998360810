/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "quote_variant_primary": "italic inline-block relative text-left after:bg-[url('/image/quotebox-blob.svg')] after:bg-no-repeat after:block after:absolute after:z-[-1] after:-top-15 after:md:-top-10 after:-left-20 after:md:-left-35",
  "quote_variant_secondary": "italic inline-block relative after:bg-[url('/image/quotebox-blob.svg')] after:bg-no-repeat after:block after:absolute after:z-[-1] after:-top-15 after:md:-top-10 after:-left-20 after:md:-left-35",
  "quote_size_default": "text-headline-sm sm:text-headline after:w-[273px] after:h-[190px] after:lg:scale-100 after:md:scale-[0.8] after:scale-[0.6]",
  "quote_colors_default": "",
  "quotationMark_variant_primary": "hidden",
  "quotationMark_variant_secondary": "hidden",
  "quotationMark_size_default": "mb-4",
  "quotationMark_colors_default": "",
  "author_variant_primary": "block text-left",
  "author_variant_secondary": "block text-left",
  "author_size_default": "text-headline-xs mt-3",
  "author_colors_default": "",
  "variant_primary": "text-center",
  "variant_secondary": "flex items-start text-left",
  "size_default": "w-full p-6",
  "colors_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Quotebox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;