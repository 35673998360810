/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "flex flex-col items-start gap-y-1",
  "label_variant_default": "text-headline-xs",
  "label_variant_floating": "text-headline-xs absolute top-3 left-3 duration-300 transform scale-75 z-10 origin-[0] -translate-y-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-1",
  "label_status_default": "",
  "label_status_error": "",
  "field_status_default": "outline-0 placeholder:text-black border-gray-300 hover:border-success-500 focus:border-success-500 focus:shadow-[0_0_4px_0] focus:shadow-success-200",
  "field_status_error": "outline-0 placeholder:text-black border-error focus:shadow-[0_0_4px_0] focus:shadow-error-200",
  "field_variant_default": "block w-full resize-none box-border p-3 text-headline-xs border border-solid rounded appearance-none",
  "field_variant_floating": "block w-full resize-none box-border p-3 pt-7 text-headline-xs border border-solid rounded appearance-none focus:ring-0 peer placeholder:!text-transparent",
  "field_size_default": "h-35 w-55",
  "field_group_variant_default": "flex flex-col-reverse gap-y-1",
  "field_group_variant_floating": "relative",
  "caption_variant_default": "text-headline-xs",
  "caption_status_error": "text-black",
  "caption_group_size_default": "gap-2.5",
  "caption_group_variant_default": "flex items-center",
  "caption_group_status_error": "text-error"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextArea");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;