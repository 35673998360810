import { JwVideoTeaser } from '@/components/JwVideo/Teaser';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';

export const MotherhoodLabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const { videoID, thumbnail, isFrontPage, teaserLink, title, hintText, stillImage, playlist, prominentTag } = data;
  const { brand } = playlist?.[0] ?? {};

  const componentProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    hintText,
    isStillImage: stillImage,
    tag: prominentTag,
    ...(isFrontPage
      ? {
          options: {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              $hint: {
                variant: 'vertical',
                colors: 'secondary',
              },
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }
      : {
          headline: `Videos från ${brand || 'Motherhood'}`,
          options: {
            variant: 'horizontal',
            colors: 'primary',
            $headline: {
              hideLine: true,
            },
            $content: {
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }),
  };

  return <JwVideoTeaser {...componentProps} />;
};
