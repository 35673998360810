import { default as BaseArticleHeaderTheme } from 'base/components/ArticleHeader/theme';

import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  base: '',
  variants: {
    colors: {
      text: 'text-cherry-blossom-500',
    },
    size: {
      text: '',
    },
    variant: {
      text: 'uppercase',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  slots: {
    ...BaseArticleHeaderTheme.slots,
  },
  variants: {
    variant: {
      second: {
        base: `mx-auto mb-5 w-full gap-3 md:max-w-col-md-10 lg:max-w-col-lg-9`,
        bylines: `text-body-sm gap-2 text-gray-600`,
        category: `order-5`,
        description: `text-preamble-sm sm:text-preamble`,
        headline: `text-headline-sm sm:text-headline-2xl`,
        media: `[&_img]:aspect-default [&_img]:object-cover`,
        tags: `order-1 col-span-2 flex`,
        date: `leading-snug text-gray-600 text-headline-xs`,
      },
    },
  },
  defaultVariants: {
    variant: 'second',
  },
});

export default Object.assign(ArticleHeader, { Tag });
