/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "tag": {
    "size_text": "text-body-sm",
    "colors_text": "text-black"
  },
  "headline_variant_default": "border-b",
  "headline_colors_default": "border-b-black",
  "headline_size_default": "text-headline pb-2 md:text-headline-lg md:pb-3",
  "letter_variant_default": "font-bold",
  "letter_colors_default": "text-black",
  "letter_size_default": "text-headline-lg mb-3 mt-5",
  "tags_variant_default": "flex flex-wrap gap-x-6 gap-y-3",
  "tags_colors_default": "",
  "tags_size_default": "gap-x-6 gap-y-3",
  "variant_default": "",
  "colors_default": "",
  "size_default": "w-full mb-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TagsIndex");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;