/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { MotherhoodLabradorArticleFooter as ResolvedLabradorArticleFooter } from 'sites/motherhood/components/labrador/ArticleFooter';

export const LabradorArticleFooter: typeof ResolvedLabradorArticleFooter = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorArticleFooter {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorArticleFooterProps = PropsFromComponent<typeof LabradorArticleFooter>;
